import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const UpdateValueLevelModal = ({
  Show,
  Hide,
  Size,
  Title,
  handleUpdate,
  origin,
  mandatory,
  dataType,
  significant,
  commentId,
  mathodId,
  valueLevelWhereclause,
  whereclauseLabel,
  setCommentId,
  setMathodId,
  setValueLevelWhereclause,
  setWhereclauseLabel,
  setDataType,
  setSignificant,
  setMandatory,
  setOrigin,
  Submit,
  setCodeList,
  codeList,
  setOrder,
  order,
}) => {
  const [variableComment, setVariableComment] = useState([]);
  const [variableMathod, setVariableMathod] = useState([]);
  const [codeListData, setCodeListData] = useState([]);

  const getActiveVariableComments = async () => {
    let data = await sdtmServices.getActiveVariableComments();
    setVariableComment(data?.data);
  };

  const getActiveVariableMathods = async () => {
    let data = await sdtmServices.getActiveVariableMathods();
    setVariableMathod(data?.data);
  };

  const getSTDMCodeList = async () => {
    let data = await sdtmServices.getSTDMCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getSTDMCodeList();
    getActiveVariableComments();
    getActiveVariableMathods();
  }, []);

  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={Hide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Order <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Order"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Value Level Whereclause <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={valueLevelWhereclause}
              onChange={(e) => setValueLevelWhereclause(e.target.value)}
              placeholder="Enter Value Level Whereclause"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Whereclause Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={whereclauseLabel}
              onChange={(e) => setWhereclauseLabel(e.target.value)}
              placeholder="Enter Whereclause Label"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Data Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setDataType(e.target.value)}
              value={dataType}
            >
              <option value="">Select Data Type</option>
              <option selected={dataType === "text"} value="text">
                Text
              </option>
              <option selected={dataType === "integer"} value="integer">
                Interger
              </option>
              <option selected={dataType === "float"} value="float">
                Float
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Length <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataType === "" ? "" : dataType === "text" ? 200 : 8}
              placeholder="Enter Length"
            />
          </Form.Group>
          {dataType === "float" && (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>
                Significant <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={significant}
                onChange={(e) => setSignificant(e.target.value)}
                placeholder="Enter Significant"
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Mandatory <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setMandatory(e.target.value)}
              value={mandatory}
            >
              <option value="">Select Mandatory</option>
              <option selected={mandatory === "yes"} value="yes">
                Yes
              </option>
              <option selected={mandatory === "no"} value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Origin <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setOrigin(e.target.value)}>
              <option value="">Select Origin</option>
              <option
                selected={origin === "Protocol" && "Protocol"}
                value="Protocol"
              >
                Protocol
              </option>
              <option
                selected={origin === "Assigned" && "Assigned"}
                value="Assigned"
              >
                Assigned
              </option>
              <option
                selected={origin === "Derived" && "Derived"}
                value="Derived"
              >
                Derived
              </option>
              <option selected={origin === "CRF" && "CRF"} value="CRF">
                CRF
              </option>
              <option selected={origin === "eDT" && "eDT"} value="eDT">
                eDT
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Comment Id</Form.Label>
            <Form.Select
              onChange={(e) => {
                setCommentId(e.target.value);
              }}
              value={commentId}
              disabled={mathodId}
            >
              <option value="">Select Comment Id</option>
              {variableComment?.map((item, index) => (
                <option
                  selected={commentId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.commentId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Mathod Id</Form.Label>
            <Form.Select
              onChange={(e) => setMathodId(e.target.value)}
              value={mathodId}
              disabled={commentId}
            >
              <option value="">Select Mathod Id</option>
              {variableMathod?.map((item, index) => (
                <option
                  selected={mathodId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.mathodId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Code List <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setCodeList(e.target.value)}>
              <option value="">Select Code List</option>
              {codeListData?.map((item, index) => (
                <option
                  selected={codeList === item?.codeList}
                  key={index}
                  value={item?.codeList}
                >
                  {item?.codeId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button
            disabled={
              !valueLevelWhereclause ||
              !whereclauseLabel ||
              !dataType ||
              !mandatory ||
              !origin
            }
            onClick={() => handleUpdate()}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateValueLevelModal;
