import React, { useState } from "react";
import { Button, Col, Form, Offcanvas } from "react-bootstrap";

const ValueLevelfilter = ({ Show, Hide, Title, setFilter, filter }) => {
  const [comment, setComment] = useState("");

  const clearFilter = () => {
    setFilter(false);
    Hide();
  };

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 405 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{ fontSize: 14, fontWeight: 600, color: "var(--bg_white)" }}
          >
            {Title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-2">
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Choose one <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setComment(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Choose Two <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setComment(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Name <span>*</span>
            </Form.Label>
            <Form.Control type="text" placeholder="Enter Name" />
          </Form.Group>
          <div className="px-1 d-flex justify-content-end footer_button">
            <Button variant="secondary">Sumbit</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ValueLevelfilter;
