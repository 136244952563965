import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";

const AddNewValueLevel = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getValueLevel,
}) => {
  const [domain, setDomain] = useState([]);
  const [version, setVersion] = useState([]);
  const [classId, setClassId] = useState("");
  const [order, setOrder] = useState("");
  const [domainId, setDomainId] = useState("");
  const [versionId, setVersionId] = useState("");
  const [sdtmClass, setSDTMClass] = useState([]);
  const [datasetName, setDatasetName] = useState([]);
  const [whereclause, setWhereclause] = useState([]);
  const [variableComment, setVariableComment] = useState([]);
  const [commentId, setCommentId] = useState("");
  const [variableMathod, setVariableMathod] = useState([]);
  const [mathodId, setMathodId] = useState("");
  const [valueLevelVeriableName, setValueLevelVeriableName] = useState("");
  const [valueLevelWhereclause, setValueLevelWhereclause] = useState("");
  const [whereclauseVeriableName, setWhereclauseVeriableName] = useState("");
  const [whereclauseLabel, setWhereclauseLabel] = useState("");
  const [dataType, setDataType] = useState("");
  const [significant, setSignificant] = useState("");
  const [mandatory, setMandatory] = useState("");
  const [origin, setOrigin] = useState("");
  const [codeList, setCodeList] = useState("");
  const [codeListData, setCodeListData] = useState([]);

  const getSTDMCodeList = async () => {
    let data = await sdtmServices.getSTDMCodeList();
    setCodeListData(data?.data);
  };

  const getActiveVersions = async () => {
    let data = await sdtmServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await sdtmServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await sdtmServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getActiveVariableComments = async () => {
    let data = await sdtmServices.getActiveVariableComments();
    setVariableComment(data?.data);
  };

  const getActiveVariableMathods = async () => {
    let data = await sdtmServices.getActiveVariableMathods();
    setVariableMathod(data?.data);
  };

  const addNewValueLevel = async () => {
    let userData = {};
    userData.valueLevelVeriableName = valueLevelVeriableName;
    userData.valueLevelWhereclause = valueLevelWhereclause;
    userData.whereclauseVeriableName = whereclauseVeriableName;
    userData.whereclauseLabel = whereclauseLabel;
    userData.dataType = dataType;
    userData.significant = significant;
    userData.mandatory = mandatory;
    userData.origin = origin;
    userData.commentId = commentId;
    userData.mathodId = mathodId;
    userData.domainId = domainId;
    userData.codeList = codeList;
    userData.order = order;
    let data = await sdtmServices.addNewValueLevel(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getValueLevel();
    }
  };

  const getValueLevelVariablesList = async (domainId) => {
    let data = await sdtmServices.getValueLevelVariablesList(domainId);
    setDatasetName(data?.data || []);
  };

  const getWithoutValueLevelVariablesList = async (domainId) => {
    let data = await sdtmServices.getWithoutValueLevelVariablesList(domainId);
    setWhereclause(data?.data || []);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
    getActiveDomains();
    getActiveVariableMathods();
    getActiveVariableComments();
    getSTDMCodeList();
  }, []);

  const closeModal = () => {
    setCommentId("");
    setMathodId("");
    setDomainId("");
    setValueLevelVeriableName("");
    setValueLevelWhereclause("");
    setWhereclauseVeriableName("");
    setWhereclauseLabel("");
    setDataType("");
    setSignificant("");
    setMandatory("");
    setOrigin("");
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Order <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Order Number"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label className="mb-1 fw-bold">
              Version <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setVersionId(e.target.value);
                getActiveClass(e.target.value);
              }}
              value={versionId}
            >
              <option value="">Select Version</option>
              {version?.map((item, index) => (
                <option
                  selected={item.versionId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.version}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label className="mb-1 fw-bold">
              Class <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setClassId(e.target.value);
                getActiveDomains(e.target.value);
              }}
              value={classId}
            >
              <option value="">Select Class</option>
              {sdtmClass?.map((item, index) => (
                <option
                  selected={item.classId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.class}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label className="mb-1 fw-bold">
              Domain <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                getValueLevelVariablesList(e.target.value);
                getWithoutValueLevelVariablesList(e.target.value);
                setDomainId(e.target.value);
              }}
              value={domainId}
            >
              <option value="">Select Domain</option>
              {domain?.map((item, index) => (
                <option
                  selected={item.domainId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.domain}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Value Level Variable Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setValueLevelVeriableName(e.target.value)}
              value={valueLevelVeriableName}
            >
              <option value="">Select Value Level Variable Name</option>
              {datasetName?.map((item, index) => (
                <option
                  key={index}
                  value={item._id}
                  selected={item.dataSetId === item._id}
                >
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Whereclause Variable Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setWhereclauseVeriableName(e.target.value)}
              value={whereclauseVeriableName}
            >
              <option value="">Select Whereclause Variable Name</option>
              {whereclause?.map((item, index) => (
                <option
                  key={index}
                  value={item._id}
                  selected={item.dataSetId === item._id}
                >
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Value Level Whereclause <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={valueLevelWhereclause}
              onChange={(e) => setValueLevelWhereclause(e.target.value)}
              placeholder="Enter Value Level Whereclause"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Whereclause Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={whereclauseLabel}
              onChange={(e) => setWhereclauseLabel(e.target.value)}
              placeholder="Enter Whereclause Label"
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Data Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setDataType(e.target.value)}
              value={dataType}
            >
              <option value="">Select Data Type</option>
              <option value="text">Text</option>
              <option value="integer">Interger</option>
              <option value="float">Float</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Length <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataType === "" ? "" : dataType === "text" ? 200 : 8}
              placeholder="Enter Length"
            />
          </Form.Group>
          {dataType === "float" && (
            <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
              <Form.Label>
                Significant <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                value={significant}
                onChange={(e) => setSignificant(e.target.value)}
                placeholder="Enter Variable Name"
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Mandatory <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setMandatory(e.target.value)}
              value={mandatory}
            >
              <option value="">Select Mandatory</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Origin <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setOrigin(e.target.value)}>
              <option value="">Select Origin</option>
              <option
                selected={origin === "Protocol" && "Protocol"}
                value="Protocol"
              >
                Protocol
              </option>
              <option
                selected={origin === "Assigned" && "Assigned"}
                value="Assigned"
              >
                Assigned
              </option>
              <option
                selected={origin === "Derived" && "Derived"}
                value="Derived"
              >
                Derived
              </option>
              <option selected={origin === "CRF" && "CRF"} value="CRF">
                CRF
              </option>
              <option selected={origin === "eDT" && "eDT"} value="eDT">
                eDT
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Comment</Form.Label>
            <Form.Select
              onChange={(e) => {
                setCommentId(e.target.value);
              }}
              value={commentId}
              disabled={mathodId}
            >
              <option value="">Select Comment</option>
              {variableComment?.map((item, index) => (
                <option
                  selected={item.commentId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.commentId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>Mathod</Form.Label>
            <Form.Select
              onChange={(e) => setMathodId(e.target.value)}
              value={mathodId}
              disabled={commentId}
            >
              <option value="">Select Mathod</option>
              {variableMathod?.map((item, index) => (
                <option
                  selected={item.mathodId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.mathodId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" className="add_sponsor_field mb-3">
            <Form.Label>
              Code List <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCodeList(e.target.value)}
              value={codeList}
            >
              <option value="">Select Code List</option>
              {codeListData?.map((item, index) => (
                <option key={index} value={item?.codeList}>
                  {item?.codeId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3 footer_button">
          <Button className="close_circle" onClick={closeModal}>
            Reset
          </Button>
          <Button
            disabled={
              !valueLevelVeriableName ||
              !valueLevelWhereclause ||
              !whereclauseVeriableName ||
              !whereclauseLabel ||
              !dataType ||
              !mandatory ||
              !origin ||
              !domainId
            }
            onClick={() => addNewValueLevel()}
          >
            {Submit}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewValueLevel;
