import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import Swal from "sweetalert2";
import RightClick from "../../../Hooks/RightClick";
import { sdtmServices } from "../../../../APIServices/CDISC/sdtmServices";
import { Button, Form } from "react-bootstrap";
import UseToggle from "../../../Hooks/useToggle";
import UploadDocumentModal from "./UploadDocumentModal";

const TerminologySidebar = ({
  getSTDMTerminologyData,
  libraryList,
  setDataList,
  libraryId,
  setLibraryId,
}) => {
  const { toggle, setToggle } = UseToggle();
  const deleteSTDMTerminology = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this File?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await sdtmServices.deleteSTDMTerminology(id);
        if (data?.statusCode === 200) {
          getSTDMTerminologyData();
        }
      }
    });
  };

  return (
    <>
      <div className="hstack justify-content-between gap-2 mb-1">
        <div className="search w-100">
          <Form.Label style={{ fontSize: 12, fontWeight: 600 }}>
            Upload STDM Terminology
          </Form.Label>
        </div>
        <div className="footer_button uploadButton">
          <Button className="w-100" title="Upload File" onClick={setToggle}>
            <i className="fa-solid fa-upload"></i>
          </Button>
        </div>
      </div>
      <div>
        <ul className="side-menu pt-2">
          {libraryList?.map((item, index) => (
            <div key={index}>
              <ContextMenuTrigger
                id={`context_${index}_${index}`}
                collect={() => ({ id: index })}
              >
                <li
                  className={`mb-1 ${
                    libraryId === item._id ? "activeHelpMenu" : ""
                  }`}
                  onClick={(e) => {
                    setDataList(item?.stdm);
                    setLibraryId(item?._id);
                  }}
                  title={item.name}
                >
                  <div
                    className="side-menu-item m-0 py-2"
                    style={{ fontSize: 13 }}
                  >
                    <span
                      className={`titleName text-capitalize  ${
                        item.status === "inactive" ? "text-muted" : ""
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                </li>
              </ContextMenuTrigger>
              <RightClick
                ContextId={`context_${index}_${index}`}
                Menu1
                MenuName1={"Delete"}
                handleClick1={() => deleteSTDMTerminology(item._id)}
                icons1={<i className="fa-solid fa-trash-can delete"></i>}
                className1="delete"
              />
            </div>
          ))}
        </ul>
      </div>
      <UploadDocumentModal
        Show={!toggle}
        Hide={setToggle}
        getSTDMTerminologyData={getSTDMTerminologyData}
        Size={"md"}
        Title={"Upload STDM File"}
        Submit={"Submit"}
      />
    </>
  );
};

export default TerminologySidebar;
